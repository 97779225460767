<template>
  <v-row no-gutters>
    <v-dialog v-model="dialogErro.status" persistent max-width="375">
      <CardDialog :dialog="dialogErro" />
    </v-dialog>

    <!-- DETALHE PRE-CARGA -->
    <v-dialog v-model="dialogDetalhePreCarga" scrollable persistent no-click-animation>
      <v-card class="primary">
        <v-card-title class="pl-4 pr-3 white--text">
          <span>Detalhe pre-carga: {{ this.descricaoPreCarga.cod }} - {{ this.descricaoPreCarga.nome }}</span>
          <v-spacer />
        </v-card-title>
        <v-card-subtitle class="pt-2 pb-3 px-0">
          <v-tabs v-model="tab" grow color="white" background-color="primary">
            <v-tab href="#tab-1" class="primary text--white">
              <v-icon>mdi-package-variant</v-icon>
              <span class="ml-2 d-none d-md-block">Produtos Pendentes para gerar nota</span>
            </v-tab>
            <v-tab href="#tab-2" class="primary text--white ml-3">
              <v-icon>mdi-truck-check-outline</v-icon>
              <span class="ml-2 d-none d-md-block">Histórico de Pré-carga</span>
            </v-tab>
          </v-tabs>
        </v-card-subtitle>
        <v-card-text class="pa-0">
          <v-tabs-items v-model="tab" class="pa-0">
            <!-- Tab para gerar notas -->
            <v-tab-item value="tab-1">
              <v-skeleton-loader v-if="carregandoSkeleton2" type="table-tbody" />
              <v-data-table v-else class="black--text" :headers="[
                { text: 'Selecionar', value: 'checkbox', align: 'left', sortable: false },
                { text: 'Idplanilha', value: 'idplanilhavenda', align: 'left', sortable: false },
                { text: 'Idsubproduto', value: 'idsubproduto', align: 'left', sortable: false },
                { text: 'Descrição', value: 'produto', align: 'left', sortable: false },
                { text: 'Num. Sequencia', value: 'numsequenciavenda', align: 'left', sortable: false },
                { text: 'QTD', value: 'qtdproduto', align: 'left', sortable: false },
                { text: 'QTD Conferida', value: 'qtdconferencia', align: 'left', sortable: false },
                { text: 'QTD Corte', value: 'qtdcorte', align: 'left', sortable: true },
              ]" :items="itensPrecarga" hide-default-footer disable-pagination mobile-breakpoint="0" fixed-header
                height="80vh">
                <template v-slot:item.checkbox="{ item }">
                  <v-checkbox v-model="item.selecionado" @change="atualizaSelecionados(item)" dense
                    hide-details></v-checkbox>
                </template>
                <template v-slot:item.idsubproduto="{ item }">
                  <span>{{ item.idsubproduto }}</span>
                </template>
                <template v-slot:item.produto="{ item }">
                  <span>{{ item.produto }}</span>
                </template>
                <template v-slot:item.qtdproduto="{ item }">
                  <span>{{ item.qtdproduto }}</span>
                </template>
                <template v-slot:item.qtdconferencia="{ item }">
                  <v-chip default label :class="coresConferencia(item.qtdproduto, item.qtdconferencia)">
                    <span>{{ item.qtdconferencia }}</span>
                  </v-chip>
                </template>
                <template v-slot:item.qtdcorte="{ item }">
                  <v-chip default label :class="coresCorte(item.qtdcorte)">
                    <span>{{ item.qtdcorte }}</span>
                  </v-chip>
                </template>

              </v-data-table>
            </v-tab-item>

            <!-- Tab de listagem de notas geradas a partir de pre-carga origem -->
            <v-tab-item value="tab-2">
              <v-data-table class="black--text" :headers="[
                { text: 'Data', value: 'data', align: 'left', sortable: false },
                { text: 'Pre-carga Origem', value: 'idprecargaorigem', align: 'left', sortable: false },
                { text: 'Pre-carga Gerada', value: 'idprecargacopia', align: 'left', sortable: false },
                { text: 'QTD Itens', value: 'qtdsku', align: 'left', sortable: false },
              ]" :items="historico.lista" hide-default-footer disable-pagination mobile-breakpoint="0" fixed-header
                height="80vh">
              </v-data-table>

            </v-tab-item>
          </v-tabs-items>

        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-3">
          <v-spacer />
          <v-btn :loading="carregando2" class="px-5" color="success" elevation="0"
            :disabled="itemSelecionados.length === 0" @click="gerarPrecarga">Gerar Pré-carga
          </v-btn>
          <v-btn :loading="carregando2" class="px-5" color="primary" elevation="0"
            @click="fechaPreCarga(descricaoPreCarga.cod), itemSelecionados = [], listar(0)">Fechar
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- PRODUTOS INVALIDOS -->
    <v-dialog v-model="dialogInvalidos" scrollable persistent no-click-animation>
      <v-card class="primary">
        <v-card-title class="pl-4 pr-3 white--text">
          {{ dialogTxt.titulo }}
        </v-card-title>
        <v-card-text class="custom-background rounded-0 white--text pt-4">
          {{ dialogTxt.msg }}
        </v-card-text>
        <v-card-text class="pa-0">
          <v-data-table class="black--text pa-0" :headers="[
            { text: 'Idsubproduto', value: 'idsubproduto', align: 'left', sortable: false },
            { text: 'Descrição', value: 'produto', align: 'left', sortable: false },
            { text: 'Num. Sequencia', value: 'numsequenciavenda', align: 'left', sortable: false },
            { text: 'QTD', value: 'qtdproduto', align: 'left', sortable: false },
            { text: 'QTD Conferida', value: 'qtdconferencia', align: 'left', sortable: false },
            { text: 'QTD Corte', value: 'qtdcorte', align: 'left', sortable: true },
          ]" :items="itemsInvalidos" hide-default-footer disable-pagination mobile-breakpoint="0" fixed-header
            height="60vh">
            <template v-slot:item.idsubproduto="{ item }">
              <span>{{ item.idsubproduto }}</span>
            </template>
            <template v-slot:item.produto="{ item }">
              <span>{{ item.produto }}</span>
            </template>
            <template v-slot:item.qtdproduto="{ item }">
              <span>{{ item.qtdproduto }}</span>
            </template>
            <template v-slot:item.qtdconferencia="{ item }">
              <v-chip default label :class="coresConferencia(item.qtdproduto, item.qtdconferencia)">
                <span>{{ item.qtdconferencia }}</span>
              </v-chip>
            </template>
            <template v-slot:item.qtdcorte="{ item }">
              <v-chip default label :class="coresCorte(item.qtdcorte)">
                <span>{{ item.qtdcorte }}</span>
              </v-chip>
            </template>

          </v-data-table>
        </v-card-text>
        <v-card-actions class="pa-3">
          <v-spacer />
          <v-btn class="px-5" color="primary" elevation="0" @click="dialogInvalidos = false, itemsInvalidos = []">Fechar
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Filtro-->
    <v-card width="100%">
      <v-card-text>
        <v-row class="pa-3">
          <v-text-field clearable autofocus type="number" v-model="busca" label="Busca de Pre-carga"
            placeholder="Informe o código da pre-carga" outlined dense hide-details @keydown.enter=buscaPreCarga(0) />
          <v-btn height="38px" class="success ml-3" @click="busca = null, listar(0)">
            Limpar
          </v-btn>
          <v-btn height="38px" class="primary ml-3" @click="buscaPreCarga(0)">
            Buscar
            <v-icon size="20" class="pl-2">mdi-magnify</v-icon>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Conteudo-->
    <v-card class="primary mt-3" width="100%">
      <v-card-title class="white--text" v-if="buscado">
        <v-col cols="12" class="pa-0">Resultados para "{{ buscado }}"</v-col>
        <v-col v-if="!carregando" style="font-size: small" class="pa-0 mb-n3 mt-n1">{{ precargas.total }} itens
          encontrados.
        </v-col>
      </v-card-title>
      <v-card-title class="white--text" v-else>
        <span class="text-h5 font-weight-medium">Pre-cargas pendentes WMS </span>
        <v-spacer />
        <span class="text-h5 font-weight-medium">
          Total:
        </span>
        <span>
          <v-chip default class="ml-2 red darken-3 white--text text-h5">{{ precargas.total }}</v-chip>
        </span>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
        <v-data-table v-else :headers="[
          { text: 'NumOrdSaida', value: 'numordemsaida', align: 'left', class: 'pl-5', sortable: false },
          { text: 'Pre-carga', value: 'idprecarga', align: 'left', sortable: false },
          { text: 'Descrição', value: 'descrprecarga', align: 'left', sortable: false },
          { text: 'QTD SKU Solicitada', value: 'qtdsku', align: 'left', sortable: false },
          { text: 'QTD SKU Atendida', value: 'qtdskuAtendido', align: 'left', sortable: false },
          { text: 'QTD SKU Atendida Parcial', value: 'qtdskuParcial', align: 'left', sortable: false },
          { text: 'QTD SKU Pendente', value: 'qtdskuPendente', align: 'left', sortable: false },
          { text: 'Data', value: 'data', align: 'left', sortable: true },
          { text: 'Dias Pendentes', value: 'diaspendentes', align: 'center', sortable: true },
          { text: 'Editar', value: 'botaoEditaPre', class: 'text-no-wrap', align: 'center', sortable: false }
        ]" :items="precargas.lista" hide-default-footer disable-pagination mobile-breakpoint="0" height="80vh"
          fixed-header density="compact">
          <template v-slot:item.idprecarga="{ item }">
            <span>{{ item.idprecarga }}</span>
          </template>
          <template v-slot:item.descrprecarga="{ item }">
            <span>{{ item.descrprecarga }}</span>
          </template>
          <template v-slot:item.qtdsku="{ item }">
            <span>{{ item.qtdsku }}</span>
          </template>

          <template v-slot:item.qtdskuAtendido="{ item }">
            <v-chip default label :class="coresQTDAtendida(item.qtdsku, item.qtdskuAtendido)">
              <span>{{ item.qtdskuAtendido }}</span>
            </v-chip>

          </template>

          <template v-slot:item.qtdskuPendente="{ item }">
            <v-chip default label :class="coresQTDPendencia(item.qtdskuPendente)">
              <span>{{ item.qtdskuPendente }}</span>
            </v-chip>
          </template>
          <template v-slot:item.data="{ item }">
            <span>{{ item.data }}</span>
          </template>

          <template v-slot:item.diaspendentes="{ item }">
            <v-chip default label :class="coresDiasPendencia(item.diaspendentes)">
              <span>{{ item.diaspendentes }}</span>
            </v-chip>
          </template>

          <template v-slot:item.botaoEditaPre="{ item }">
            <v-icon class="ml-2" @click="detalhePreCarga(item), listarHistoricoPreCarga(item)" color="primary"
              size="20">mdi-truck-delivery-outline</v-icon>
          </template>
        </v-data-table>
        <!-- <v-col class="text-center" cols="12" v-if="precargas.total >= 1">
          <Paginacao :total="precargas.total" @atualizar="listar" :pgLimitInt="pgLimit" :carregando="carregando"
            :pg="pgSelecionada" />
        </v-col> -->
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
// import Paginacao from "../Widgets/Paginacao.vue";
import CardDialog from "../Widgets/CardDialog";

export default {
  name: "ListagemPreCarga",
  components: { CardDialog },
  data: () => ({
    dialog: false,
    dialogErro: {
      status: false,
      title: "",
      msg: "",
      icon: "",
      iconColor: "",
    },
    espaco: ' ',
    busca: null,
    carregando: false,
    carregando2: false,
    carregando3: false,
    carregandoSkeleton: false,
    carregandoSkeleton2: false,
    carregandoSkeleton3: false,
    dialogDetalhePreCarga: false,
    dialogInvalidos: false,
    dialogTxt:{
      titulo: '',
      msg: ''
    },
    precargas: {
      lista: [],
      total: null
    },
    historico: {
      lista: [],
      total: 0
    },
    itensPrecarga: [],
    descricaoPreCarga: {
      cod: null,
      nome: ''
    },
    notasPrecarga: [
      {
        numnota: 1,
        idprecargaorigem: 654,
        idprecargagerado: 9783,
        qtditens: 10,
        data: '2025-01-03'
      },
      {
        numnota: 2,
        idprecargaorigem: 654,
        idprecargagerado: 7154,
        qtditens: 5,
        data: '2025-01-05'
      },
      {
        numnota: 3,
        idprecargaorigem: 654,
        idprecargagerado: 7468,
        qtditens: 25,
        data: '2025-01-07'
      }
    ],
    itemSelecionados: [],
    itemsInvalidos: [],
    itemsValidos: [],
    itemsNovaPreCarga: [],
    pgLimit: 20,
    pgSelecionada: 0,
    buscado: "",
    retornoValida: null,
    emUso: false,
    tab: null,
    errorMessage: ''
  }),
  computed: {
    ...mapState(["backendUrl", "pgLimit", "usuario"]),
  },
  methods: {
    coresCorte(qtdCorte) {
      if (qtdCorte == 0) {
        return 'green white--text'
      } else {
        return 'orange accent-4 white--text'
      }
    },
    coresConferencia(qtdProduto, qtdConferencia) {
      if (qtdProduto == qtdConferencia) {
        return 'green white--text'
      } else if (qtdConferencia > 0) {
        return 'yellow accent-2'
      } else if (qtdConferencia == 0) {
        return 'red darken-3 white--text'
      }
    },
    coresDiasPendencia(dias) {
      if (dias == 0 || dias == 1) {
        return 'green white--text'
      } else if (dias == 2 || dias == 3) {
        return 'yellow accent-2'
      } else if (dias >= 4) {
        return 'red darken-3 white--text'
      }
    },
    coresQTDAtendida(qtdTotal, qtdAtendida) {
      if (qtdTotal === qtdAtendida) {
        return 'green white--text'
      } else if (qtdAtendida > 0) {
        return 'yellow accent-2'
      } else if (qtdAtendida == 0) {
        return 'red darken-3 white--text'
      }

    },
    coresQTDPendencia(qtdPendencia) {
      if (qtdPendencia > 0) {
        return 'red darken-3 white--text'
      } else {
        return 'green white--text'
      }
    },
    // listagem geral de pre-cargas
    listar(pg) {
      this.precargas.lista = [];
      this.precargas.total = null;
      this.pgSelecionada = pg;
      this.carregando = true;
      this.carregandoSkeleton = true;
      return axios
        .post(`${this.backendUrl}cd/precarga/listar`, {})
        .then((res) => {
          this.precargas.lista = res.data.lista;
          this.precargas.total = res.data.total;
          this.carregando = false;
          this.carregandoSkeleton = false;
        })
        .catch(() => {
          this.carregandoSkeleton = false;
          this.precargas.lista = [];
          this.precargas.total = null;
          this.carregando = false;
        });
    },
    listarHistoricoPreCarga(item) {
      this.historico.lista = [];
      this.historico.total = null;
      this.carregando3 = true;
      this.carregandoSkeleton3 = true;
      return axios
        .post(`${this.backendUrl}cd/precarga/historico/listar`, {
          idprecarga: item.idprecarga
        })
        .then((res) => {
          this.historico.lista = res.data.lista;
          this.historico.total = res.data.total;
          this.carregando3 = false;
          this.carregandoSkeleton3 = false;
        })
        .catch(() => {
          this.carregandoSkeleton3 = false;
          this.historico.lista = [];
          this.historico.total = null;
          this.carregando3 = false;
        });
    },
    // buscar pre-carga pelo codigo
    buscaPreCarga(pg) {
      this.precargas = [];
      this.pgSelecionada = pg;
      this.carregando = true;
      this.carregandoSkeleton = true;
      return axios
        .post(`${this.backendUrl}cd/precarga/listar`, {
          idprecarga: parseInt(this.busca, 10)
        })
        .then((res) => {
          this.precargas = res.data;
          this.carregando = false;
          this.carregandoSkeleton = false;
        })
        .catch(() => {
          this.carregandoSkeleton = false;
          this.precargas = [];
          this.carregando = false;
        });
    },
    detalhePreCarga(item) {
      this.itensPrecarga = [];
      this.descricaoPreCarga.cod = item.idprecarga;
      this.descricaoPreCarga.nome = item.descrprecarga;
      this.carregando2 = true;
      this.carregandoSkeleton2 = true;
      this.errorMessage = '';
      return axios
        .post(`${this.backendUrl}cd/precarga/sku/listar`, {
          idprecarga: item.idprecarga
        })
        .then((res) => {
          this.itensPrecarga = res.data;
          this.carregando2 = false;
          this.carregandoSkeleton2 = false;
          if (this.itensPrecarga === null) {
            return this.listar(0);
          } else {
            this.dialogDetalhePreCarga = true;
          }
        })
        .catch((error) => {
          this.itensPrecarga = [];
          this.carregando2 = false;
          this.carregandoSkeleton2 = false;
          this.errorMessage = error.response.data;
          // Dialog de aviso
          this.dialogErro.title = "Atenção";
          this.dialogErro.msg = this.errorMessage.erro;
          this.dialogErro.icon = "mdi-alert";
          this.dialogErro.iconColor = "red";
          this.dialogErro.status = true;
        });

    },
    fechaPreCarga(item) {
      return axios
        .post(`${this.backendUrl}cd/precarga/log/inserir`, {
          idprecarga: item,
          erp: 2,
          acessou: false,
          idusuario: 2079
        })
        .then((res) => {
          this.retornoValida = res.data;
          this.dialogDetalhePreCarga = false;
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
        });

    },
    atualizaSelecionados(item) {
      if (item.selecionado) {
        // add item marcado
        this.itemSelecionados.push(item);
      } else {
        // remove item marcado
        item.selecionado = false;
        this.itemSelecionados = this.itemSelecionados.filter(i => i.idsubproduto !== item.idsubproduto);
      }
    },
    gerarPrecarga() {
      this.itemsNovaPreCarga = [];
      this.validacaoProdutos(this.itemSelecionados);

      if(this.itemsValidos.length > 0){
      this.itemsNovaPreCarga = this.itemsValidos;
      return axios
        .post(`${this.backendUrl}cd/precarga/precarga/inserir`, {
          idprecarga: this.descricaoPreCarga.cod,
          idsubproduto: this.itemsNovaPreCarga
        })
        .then(() => {
          this.carregando2 = false;
          this.carregandoSkeleton2 = false;
          this.dialogErro.title = "Sucesso";
          this.dialogErro.msg = "Pré-carga gerada com sucesso!";
          this.dialogErro.icon = "mdi-check-circle-outline";
          this.dialogErro.iconColor = "success";
          this.dialogErro.status = true;
          this.fechaPreCarga(this.descricaoPreCarga.cod);
          this.dialogDetalhePreCarga = false;
          this.itemSelecionados = [];
          if (this.itemsInvalidos.length > 0) {
            this.dialogInvalidos = true;
            this.dialogTxt.titulo = 'ATENÇÃO';
            this.dialogTxt.msg = 'Os itens listados abaixo ainda não foram completamente conferidos e, por isso, não foram incluídos na nova pré-carga gerada.';
          }
          this.listar(0)
        })
        .catch((error) => {
          this.itemsNovaPreCarga = [];
          this.carregando2 = false;
          this.carregandoSkeleton2 = false;
          this.errorMessage = error.response.data;
          // Dialog de aviso
          this.dialogErro.title = "Atenção";
          this.dialogErro.msg = this.errorMessage.erro;
          this.dialogErro.icon = "mdi-alert";
          this.dialogErro.iconColor = "red";
          this.dialogErro.status = true;
        });
      }else{
        this.dialogInvalidos = true;
        this.dialogTxt.titulo = 'AÇÃO NÃO PERMITIDA';
        this.dialogTxt.msg = 'Nenhum dos itens selecionados foi totalmente conferido, portanto, não foi possível gerar a pré-carga. Por favor, confira os itens listados abaixo.'
      }
    },
    validacaoProdutos(produtos) {
      this.itemsInvalidos = this.filtrarProdutosInvalidos(produtos);
      this.itemsValidos = this.filtrarProdutosValidos(produtos);
      console.log('items invalidos: ');
      console.log(this.itemsInvalidos);
      console.log('items validos: ');
      console.log(this.itemsValidos);
    },
    filtrarProdutosInvalidos(produtos) {
      return produtos.filter(produto => {
        if (produto.qtdcorte === 0) {
          return produto.qtdproduto !== produto.qtdconferencia;
        } else {
          return (produto.qtdproduto - produto.qtdcorte) !== produto.qtdconferencia;
        }
      });
    },
    filtrarProdutosValidos(produtos) {
      return produtos.filter(produto => {
        if (produto.qtdcorte === 0) {
          return produto.qtdproduto === produto.qtdconferencia;
        } else {
          return (produto.qtdproduto - produto.qtdcorte) === produto.qtdconferencia;
        }
      });
    },
    handleBeforeUnload(event) {
      if (this.dialogDetalhePreCarga) {
        event.preventDefault();
        event.returnValue =
          "Você tem alterações não salvas. Tem certeza de que deseja sair?";
      }
    },
    // Chamar fechaPreCarga ao confirmar saída
    handleUnload() {
      if (this.dialogDetalhePreCarga) {
        this.fechaPreCarga(this.descricaoPreCarga.cod);
      }
    },

    async init() {
      await this.listar(0);
    },
  },
  mounted() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("unload", this.handleUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("unload", this.handleUnload);
  },
  created() {
    this.init();
  },
};
</script>
<style scoped>
.custom-background {
  background-color: red;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  display: block;
}
</style>
